// import { Formik } from "formik";
import { Formik, FormikErrors } from "formik";
import { useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import AccountRoleControl from "../../components/Accounts/AccountRoleControl";
import ListAPIErrors, { APIError } from "../../components/Error/ListAPIErrors";
import { AccountRole } from "../../models/Account";
import { generatePassword } from "../../utils/getOneTimePassword";
import loginIdValidationSchema from "../../utils/validation/loginIdValidationSchema";
import passwordValidationSchema from "../../utils/validation/passwordValidationSchema";
import { useCreateAccount, useRegisterAccountByEmail } from "../queries";

function NewOrgAccount() {
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
  };

  const createAccount = useCreateAccount();
  const registerAccount = useRegisterAccountByEmail();
  const [roles, setRoles] = useState<string[]>([]);
  const [APIErrors, setAPIErrors] = useState<APIError[]>([]);
  const [isDisabledBtn, setIsDisabledBtn] = useState(false);

  const emailRegistrationLink = (values: {
    firstName: string;
    lastName: string;
    email: string;
  }) => {
    setIsDisabledBtn(true);
    setAPIErrors([]);
    const body = {
      name: `${values.firstName} ${values.lastName}`,
      email: values.email,
      roles: roles,
      type: "Org"
    };

    registerAccount.mutate(body, {
      onSuccess: newOrgAccount => {
        setTimeout(() => {
          setIsDisabledBtn(false);
          navigate("/org-accounts/edit", {
            state: { account: newOrgAccount.account, newAccount: true }
          });
        }, 1500);
      },
      onError: (err: any) => {
        if (err.errors && Array.isArray(err.errors)) {
          // Extract the errors array from the err object
          setAPIErrors(err.errors);
        } else {
          console.error("Unexpected error structure:", err);
        }
        setIsDisabledBtn(false);
      }
    });
  };

  const handleAssignRole = (role: AccountRole) => {
    if (!role) return;
    setRoles([...roles, role.name]);
  };

  const handleRemoveRole = (role: AccountRole) => {
    if (!role) return;
    setRoles(roles.filter(x => role.name));
  };

  const validateUserInformation = (
    values: {
      firstName: string;
      lastName: string;
      email: string;
    },
    errors: FormikErrors<{
      firstName: string;
      lastName: string;
      email: string;
    }>
  ) => {
    if (
      values.lastName !== "" &&
      values.lastName !== "" &&
      values.email !== "" &&
      !errors.firstName &&
      !errors.lastName &&
      !errors.email
    ) {
      return true;
    }
    return false;
  };

  const schema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("Email Address is required"),
    loginId: loginIdValidationSchema,
    password: passwordValidationSchema
  });

  return (
    <>
      <Formik
        validationSchema={schema}
        onSubmit={values => {
          setIsDisabledBtn(true);
          setAPIErrors([]);
          const body = {
            ...values,
            name: `${values.firstName} ${values.lastName}`,
            type: "Org",
            status: "Reset Password",
            roles: roles
          };

          createAccount.mutate(body, {
            onSuccess: newOrgAccount => {
              setTimeout(() => {
                setIsDisabledBtn(false);
                navigate("/org-accounts/edit", {
                  state: { account: newOrgAccount, newAccount: true }
                });
              }, 1500);
            },
            onError: (err: any) => {
              if (err.errors && Array.isArray(err.errors)) {
                // Extract the errors array from the err object
                setAPIErrors(err.errors);
              } else {
                console.error("Unexpected error structure:", err);
              }
              setIsDisabledBtn(false);
            }
          });
        }}
        initialValues={{
          firstName: "",
          lastName: "",
          email: "",
          loginId: "",
          password: "",
          sendEmail: true
        }}
      >
        {({
          handleSubmit,
          handleChange,
          values,
          touched,
          errors,
          setFieldValue,
          setFieldTouched
        }) => (
          <Form onSubmit={handleSubmit}>
            <Card>
              <Card.Body>
                <Card.Title>
                  User Information
                  <span className="label-required">(Required)</span>
                </Card.Title>
                <Row>
                  <Form.Group as={Col} xs={6} className="mb-3">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={!!errors.firstName && touched.firstName}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.firstName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group as={Col} xs={6} className="mb-3">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="lastName"
                      onChange={handleChange}
                      isInvalid={!!errors.lastName && touched.lastName}
                    ></Form.Control>
                    <Form.Control.Feedback type="invalid">
                      {errors.lastName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Row>
                    <Form.Group as={Col} xs={6} className="mb-3">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        onChange={handleChange}
                        isInvalid={!!errors.email && touched.email}
                      ></Form.Control>
                      <Form.Control.Feedback type="invalid">
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </Row>
                </Row>
              </Card.Body>
            </Card>

            <AccountRoleControl
              handleAssignRole={handleAssignRole}
              handleRemoveRole={handleRemoveRole}
            />
            <Card>
              <Card.Body>
                <Card.Title className="mb-3">Registration Method</Card.Title>
                <Row>
                  <Col className="border-end border-dark">
                    <h6>Create Account with a one-time password</h6>
                    <Card.Subtitle className="text-body-tertiary mb-3">
                      An Organization Account will be created with a one-time
                      password that you can share. The user will be required to
                      change their password the first time they log on. Please
                      note that the user will be unable to change their
                      username.
                    </Card.Subtitle>

                    <Row>
                      <Form.Group as={Col} xs={6} className="mb-3">
                        <Form.Label>Username</Form.Label>
                        <Form.Control
                          type="text"
                          name="loginId"
                          onChange={handleChange}
                          isInvalid={!!errors.loginId && touched.loginId}
                        ></Form.Control>
                        <Form.Control.Feedback type="invalid">
                          {errors.loginId}
                        </Form.Control.Feedback>
                      </Form.Group>
                    </Row>

                    <div className="mb-3">
                      <Row className="align-items-end">
                        <Form.Group as={Col} xs={6}>
                          <Form.Label>One-time Password</Form.Label>
                          <Form.Control
                            type="text"
                            name="password"
                            onChange={handleChange}
                            isInvalid={!!errors.password && touched.password}
                            value={values.password}
                          ></Form.Control>
                        </Form.Group>
                        <Form.Group as={Col} xs={6}>
                          <Button
                            variant="outline-dark"
                            onClick={() =>
                              setFieldValue("password", generatePassword())
                            }
                          >
                            Generate password
                          </Button>
                        </Form.Group>
                      </Row>
                      {!!errors.password && touched.password && (
                        <div className="invalid-feedback d-block">
                          {errors.password}
                        </div>
                      )}
                    </div>
                    <div>
                      <Form.Check
                        className="mb-3"
                        inline
                        label="Email account their username and one-time password"
                        name="sendEmail"
                        type="checkbox"
                        checked={values.sendEmail}
                        onChange={handleChange}
                      />
                    </div>
                    <Button
                      type="submit"
                      variant="success"
                      className="my-3"
                      disabled={isDisabledBtn}
                    >
                      Create Account with username and password
                    </Button>
                  </Col>
                  <Col>
                    <h6>
                      Send a registration link to complete account registration
                    </h6>
                    <Card.Subtitle className="text-body-tertiary mb-3">
                      An email with a registration link will be send to the
                      email address provided. The user will then finish their
                      account registration with a username and password.
                    </Card.Subtitle>
                    <Button
                      variant="success"
                      className="my-3"
                      disabled={isDisabledBtn}
                      onClick={() => {
                        if (validateUserInformation(values, errors)) {
                          emailRegistrationLink(values);
                        } else {
                          // Mark fields as touched to display validation errors
                          setFieldTouched("firstName", true);
                          setFieldTouched("lastName", true);
                          setFieldTouched("email", true);
                        }
                      }}
                    >
                      Email Registration Link
                    </Button>
                  </Col>
                </Row>
              </Card.Body>
            </Card>
          </Form>
        )}
      </Formik>

      <ListAPIErrors errors={APIErrors} setErrors={setAPIErrors} />

      <Button variant="outline-primary" onClick={handleCancel}>
        Cancel
      </Button>
    </>
  );
}

export default NewOrgAccount;
