import { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { LastUpdatedLabel, LogItPageHeader } from "../../components";
import { PasswordReset, UserInformation } from "../../components/Accounts";
import { Account } from "../../models";
import { useSelfAccountQuery } from "../queries";

function AccountSettings() {
  const navigate = useNavigate();

  const { data: accountData } = useSelfAccountQuery();
  const [account, setAccount] = useState<Account>();

  useEffect(() => {
    if (!accountData) return;
    setAccount(accountData);
  }, [accountData]);

  const renderCancelButton = () => {
    return (
      <Button variant="outline-primary" onClick={() => navigate(-1)}>
        Cancel
      </Button>
    );
  };

  return (
    <>
      <LogItPageHeader
        heading={"Account Settings"}
        description={"Edit your account settings"}
        renderButtons={renderCancelButton}
      />

      <UserInformation accountData={account} showAccountType={true} />

      <PasswordReset
        accountData={account}
        setAccountData={setAccount}
        showEmailPasswordResetButton={false}
        showRequirePasswordChange={false}
      />

      <Row className="mb-5">
        <Col xs={6}>
          <Button variant="outline-primary" onClick={() => navigate(-1)}>
            Cancel
          </Button>
        </Col>
        <Col xs={6}>
          <LastUpdatedLabel
            account={account?.editor}
            timeStamp={account?.updatedAt}
            label="Updated:"
          />
          <LastUpdatedLabel
            account={account?.creator}
            timeStamp={account?.createdAt}
            label="Created:"
          />
        </Col>
      </Row>
    </>
  );
}

export default AccountSettings;
